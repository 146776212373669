// NavigationContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoctor } from '../../services/login';
import { IDoctor } from '../../types/models/Doctor';
type NavigationContextType = {
  triggerRedirect: (url: string) => void;
  doctor: IDoctor | null;
  logout: () => void;
  errors: string[];
  setErrors: (errors: string[]) => void;
} | null
const NavigationContext = createContext<NavigationContextType>(null);

export const NavigationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [errors, setErrors] = useState<string[]>([])
  const navigate = useNavigate();
  const doctor = getDoctor();

  const logout = useCallback(() => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('doctor');
    navigate('/login');
  }, [])

  // Function to trigger a redirect
  const triggerRedirect = useCallback((url: string) => {
    navigate(url);
  }, [navigate]);

  return (
    <NavigationContext.Provider value={{ triggerRedirect, doctor, logout,errors,setErrors }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};